import React from "react";
import {
  Svg, Path
} from '../../../style';

function Icon() {
  return (
    <Svg
      className="icon-services product"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 465.987 465.987"
      version="1.1"
      viewBox="-34 -3 511 517.000"
      xmlSpace="preserve"
    >
      <Path d="M372.735 236.547a8 8 0 00-14.32 3.672l-4.44 31.12c-.107.4-.247.79-.416 1.168a143.811 143.811 0 0019.56-35.448l-.384-.512zM346.047 278.203c-.377 0-.754-.024-1.128-.072l-47.52-6.792a8 8 0 01-6.784-9.054v-.002l4.44-31.12a8 8 0 00-12.712-7.528l-25.152 18.856a8 8 0 01-11.2-1.6l-28.8-38.4a8 8 0 011.6-11.2l25.144-18.856a8 8 0 00-3.672-14.32l-31.12-4.448a8 8 0 01-6.793-9.047l.001-.009 6.792-47.512a8 8 0 019.047-6.793l.009.001 31.12 4.448a7.952 7.952 0 008-3.848 7.832 7.832 0 00-.48-8.88l-18.84-25.136a8 8 0 011.6-11.2l4.536-3.4a146.69 146.69 0 00-22.032.592C143.087 51.905 86.347 123.274 95.369 202.29a143.998 143.998 0 0065.438 104.945 45.871 45.871 0 0121.696 38.424v32.328c0 13.255 10.745 24 24 24h64c13.255 0 24-10.745 24-24v-31.664a47.127 47.127 0 0122.16-39.376 144.964 144.964 0 0033.384-29.92 7.99 7.99 0 01-4 1.176zm-99.544 99.784h-16v-24h16v24zm-16-40c-.005-21.152-13.048-40.112-32.8-47.68-57.609-22.526-86.049-87.487-63.523-145.096a111.999 111.999 0 0144.635-53.992l8.488 13.536c-44.875 28.237-58.362 87.505-30.125 132.38a96.002 96.002 0 0046.301 38.284c25.928 9.917 43.044 34.808 43.024 62.568h-16zM206.503 441.987v8c0 8.837 7.163 16 16 16h32c8.837 0 16-7.163 16-16v-8h-64z"></Path>
      <Path d="M156.487 313.987a151.402 151.402 0 01-13.672-9.992c-12.077 23.51-40.925 32.778-64.435 20.702a47.857 47.857 0 01-25.989-42.71c.037-24.187 18.05-44.575 42.048-47.592a152.348 152.348 0 01-6.336-27.128l-1.4 6.656c-1.818 8.647-10.303 14.183-18.95 12.365a15.983 15.983 0 01-5.778-2.477l-16.64-11.424-14.576 14.544 11.456 16.656c5.014 7.276 3.18 17.24-4.096 22.254a16.003 16.003 0 01-5.784 2.482l-19.944 4.2v19.008l19.944 4.2c8.647 1.821 14.181 10.307 12.36 18.954a15.994 15.994 0 01-2.488 5.79l-11.448 16.568 14.576 14.544 16.624-11.456c7.276-5.014 17.24-3.18 22.254 4.096a16.003 16.003 0 012.482 5.784l4.192 19.976h19.016l4.192-19.944c1.82-8.647 10.305-14.182 18.952-12.362a16.003 16.003 0 015.784 2.482l16.616 11.424 17.16-17.16a39.05 39.05 0 00-16.12-20.44z"></Path>
      <Path d="M206.503 409.987H270.503V425.987H206.503z"></Path>
      <Path d="M438.255 137.915c-13.121-1.876-22.237-14.035-20.361-27.156a23.997 23.997 0 019.361-15.804l18.744-14.064-19.2-25.6-18.744 14.056c-10.602 7.956-25.646 5.81-33.601-4.791a24.002 24.002 0 01-4.567-17.769l3.312-23.2-31.672-4.52-3.312 23.2c-1.877 13.121-14.035 22.237-27.156 20.361a23.997 23.997 0 01-15.804-9.361l-14.064-18.776-25.6 19.2 14.056 18.744c7.954 10.603 5.807 25.646-4.796 33.601a23.998 23.998 0 01-17.804 4.559l-23.2-3.312-4.544 31.68 23.2 3.312c13.121 1.877 22.237 14.035 20.361 27.156a23.997 23.997 0 01-9.361 15.804l-18.736 14.064 19.2 25.6 18.744-14.056c10.603-7.954 25.646-5.807 33.601 4.796a24 24 0 014.559 17.804l-3.312 23.2 31.744 4.544 3.312-23.2c1.877-13.121 14.035-22.237 27.156-20.361a23.997 23.997 0 0115.804 9.361l14.064 18.736 25.6-19.2-14.104-18.776c-7.951-10.605-5.8-25.648 4.805-33.599a24 24 0 0117.763-4.561l23.2 3.312 4.52-31.672-23.168-3.312zm-97.864 50.184c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48c-.031 26.497-21.503 47.969-48 48z"></Path>
      <Path d="M390.503 297.987H446.503V313.987H390.503z"></Path>
      <Path
        d="M398.504 334.75H414.504V397.23H398.504z"
        transform="rotate(-50.201 406.493 365.982)"
      ></Path>
      <Path d="M342.503 361.987H358.503V417.987H342.503z"></Path>
      <Path
        d="M5.519 129.987H71.48700000000001V145.97899999999998H5.519z"
        transform="rotate(-14.035 38.509 138)"
      ></Path>
      <Path
        d="M42.502 35.585H58.494V104.40100000000001H42.502z"
        transform="rotate(-54.463 50.5 69.996)"
      ></Path>
      <Path
        d="M94.329 0.952H110.329V67.008H94.329z"
        transform="rotate(-14.342 102.31 33.977)"
      ></Path>
    </Svg>
  );
}

export default Icon;
