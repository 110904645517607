import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import axios from "axios";

import { Container, Row, Col } from "reactstrap";
import {
  Page,
  Content,
  Box,
  Background,
  Footer,
  Card,
  Title,
  H2,
  H3,
  H4,
  Text,
  Span,
  Image,
  Link,
  NavbarMain,
  NavbarBrandMain,
  Form,
  Input,
  Button,
  Img,
} from "../../style";
import SvgTechnology from "../../components/svg/Technology";
import SvgProduct from "../../components/svg/Product";
import SvgBusiness from "../../components/svg/Business";
import imgLogo from "../../assets/logo.png";
import imgRobot from "../../assets/robot.png";
import imgPhone from "../../assets/phone.png";
import imgGraph from "../../assets/graph.png";

import load from "../../assets/load.gif";

import { en, pt_br } from "./lang.json";

export const Home = () => {
  const [searchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [lang, setLang] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();

    axios
      .post("/contato", {
        nome: name,
        email: email,
        telefone: phone,
        mensagem: msg,
      })
      .then(function (response) {
        console.log("success");
        setLoading(true);
        setSuccess(true);

        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch(function (error) {
        console.log("error");
        setLoading(true);
        setSuccess(true);

        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  }

  function maskPhoneEn(value) {
    let mask = "";

    mask = value.replace(/\D/g, "");
    // mask = mask.replace(/^0/, "");

    if (mask.length !== 0) {
      if (mask.length > 11) {
        mask = mask.replace(/^(\d\d\d)(\d{3})(\d{5}).*/, "($1) $2-$3");
      } else if (mask.length > 7) {
        mask = mask.replace(/^(\d\d\d)(\d{3})(\d{0,5}).*/, "($1) $2-$3");
      } else if (mask.length > 2) {
        mask = mask.replace(/^(\d\d\d)(\d{0,3})/, "($1) $2");
      } else if (value.trim() !== "") {
        mask = mask.replace(/^(\d*)/, "($1");
      }
    }

    return mask;
  }

  function maskPhonePtBr(value) {
    let mask = "";

    mask = value.replace(/\D/g, "");
    mask = mask.replace(/^0/, "");

    if (mask.length !== 0) {
      if (mask.length > 11) {
        mask = mask.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (mask.length > 7) {
        mask = mask.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
      } else if (mask.length > 2) {
        mask = mask.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else if (value.trim() !== "") {
        mask = mask.replace(/^(\d*)/, "($1");
      }
    }

    return mask;
  }

  useEffect(() => {
    const lang = searchParams.get("lang");

    if (lang === "pt-br") {
      setLang(pt_br);
    } else {
      setLang(pt_br);
    }
  }, [searchParams]);

  return (
    <>
      <a
        href="https://api.whatsapp.com/send?phone=5561996819313&text=Olá, gostaria de mais informações sobre o PixaFlow. Achei esse contato no site https://pixaflow.com/"
        className="float"
        target="_blank"
        id="btn_whatsapp"
      >
        <i className="fa fa-whatsapp my-float"></i>
      </a>

      {lang ? (
        <Page>
          <NavbarMain color="light" dark expand="md">
            <Container>
              <NavbarBrandMain href="/">
                <Image src={imgLogo} alt="logo" className="img-fluid" />
                PixaFlow
              </NavbarBrandMain>
            </Container>
          </NavbarMain>
          <Background className="bg-home d-flex justify-content-center align-items-center">
            <Container>
              <Row className="text-center text-lg-start">
                <Col className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-left align-items-center my-5 my-lg-0">
                  <Content>
                    <Title className="text-home">
                      Soluções em tecnologia para
                      <Span className="heading-landing__highlight"></Span>
                    </Title>
                    <Link
                      className="btn btn-primary mt-4"
                      href="#more"
                      styles={{ minWitdh: "200px" }}
                    >
                      Saiba mais
                    </Link>
                  </Content>
                </Col>
                <Col className="col-12 col-lg-6 mb-4 mb-lg-0 px-5">
                  <Background className="bg-cube">
                    <Image
                      src={imgRobot}
                      alt="banner robot in home page"
                      className="img-fluid animation up-and-down"
                    />
                  </Background>
                </Col>
              </Row>
            </Container>
          </Background>

          <Content className="bg-page" id="more">
            <Container id="services">
              <Row className="text-center">
                <Col className="col-12 my-5">
                  <H4 className="text-muted text-uppercase">Soluções</H4>
                </Col>
                <Col className="col-12 col-md mb-5">
                  <Card className="card-services">
                    <SvgBusiness />
                    <H3 className="text-white mt-4">Processos</H3>
                    <Text className="text-muted px-4">
                      Mapeamento e automação
                    </Text>
                  </Card>
                </Col>
                <Col className="col-12 col-md mb-5">
                  <Card className="card-services">
                    <SvgProduct />
                    <H3 className="text-white mt-4">Analytics</H3>
                    <Text className="text-muted px-4">
                      Inteligência Artificial e BI
                    </Text>
                  </Card>
                </Col>
                <Col className="col-12 col-md mb-5">
                  <Card className="card-services">
                    <SvgTechnology />
                    <H3 className="text-white mt-4">Desenvolvimento</H3>
                    <Text className="text-muted px-4">
                      Sistemas web e mobile
                    </Text>
                  </Card>
                </Col>
              </Row>
            </Container>
            <Container className="text-center text-lg-start pt-5 pb-0 py-lg-5">
              <Row>
                <Col className="col-12 col-lg d-flex justify-content-center align-items-center">
                  <Image
                    src={imgPhone}
                    alt="banner phone in home page"
                    className="img-fluid animation"
                  />
                </Col>
                <Col className="col-12 col-lg d-flex justify-content-center align-items-center mt-5 mt-lg-0">
                  <Title className="text-description">
                    Empresa focada em soluções efetivas para problemas reais.
                  </Title>
                </Col>
              </Row>
            </Container>
            <Container className="text-center text-lg-start pt-0 pb-5 py-lg-5">
              <Row>
                <Col className="col-12 col-lg col-xl-8 d-flex justify-content-center align-items-center mb-5">
                  <H2 className="text-description text-muted mt-4">
                    Nosso conhecimento e experiência nos permitem desenvolver
                    estratégias criativas que trazem ótimos resultados a curto,
                    médio e longo prazo em todas as soluções.
                  </H2>
                </Col>
                <Col className="col-12 col-lg d-flex justify-content-center align-items-center mb-5 d-none d-lg-block">
                  <Image
                    src={imgGraph}
                    alt="banner graph in home page"
                    className="img-fluid"
                  />
                </Col>
              </Row>
            </Container>
          </Content>

          <Footer className="py-5">
            <Container>
              <Row>
                <Col className="col-12 col-lg-6 mx-auto text-center">
                  {success ? (
                    <>
                      {loading ? (
                        <Box>
                          <Img src={load} height="30" />
                        </Box>
                      ) : (
                        <>
                          <Box className="py-5">
                            <H2 className="text-description text-primary">
                              {lang.form_footer.title_response}
                            </H2>
                            <H2 className="text-description text-muted mt-4">
                              {lang.form_footer.subtitle_response}
                            </H2>
                          </Box>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <H2 className="text-description text-muted mb-4">
                        {lang.form_footer_title}
                      </H2>

                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <Box className="form-group mb-3 mt-5">
                          <Input
                            type="text"
                            className="form-control"
                            id="nome"
                            name="nome"
                            placeholder={lang.form_footer.name}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            required
                          />
                        </Box>

                        <Box className="form-group mb-3">
                          <Input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder={lang.form_footer.email}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            required
                          />
                        </Box>

                        <Box className="form-group mb-3">
                          <Input
                            type="text"
                            className="form-control"
                            id="telefone"
                            name="telefone"
                            placeholder={lang.form_footer.phone}
                            value={phone}
                            onChange={(event) =>
                              setPhone(
                                lang.mode !== "en"
                                  ? maskPhonePtBr(event.target.value)
                                  : maskPhoneEn(event.target.value)
                              )
                            }
                          />
                        </Box>

                        <Box className="form-group mb-3">
                          <Input
                            className="form-control"
                            id="id_msg"
                            name="mensagem"
                            rows="4"
                            placeholder={lang.form_footer.message}
                            type="textarea"
                            value={msg}
                            onChange={(event) => setMsg(event.target.value)}
                            required
                          ></Input>
                        </Box>

                        <Box className="pt-4 text-center">
                          <Button
                            type="submit"
                            className="btn primary-outline-btn"
                          >
                            <Span className="ml-1">
                              {lang.form_footer.submit}
                            </Span>
                          </Button>
                        </Box>
                      </Form>
                    </>
                  )}
                </Col>
              </Row>
              {/* <Row className="text-center">
                <Col className="col-12 col-lg-6">
                  <Text className="mt-3">
                    &copy; Pixaflow |{" "}
                    <Link
                      href="https://blog.pixaflow.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      Conheça nosso blog
                    </Link>
                  </Text>
                  <Text>Núcleo de Pesquisa, Desenvolvimento e Inovação.</Text>
                  <Text className="text-white mb-0">CNPJ 34.678.688/0001-85</Text>
                </Col>
                <Col className="col-12 col-lg-6">
                  <H4 className="text-white my-4 mt-lg-0">Junte-se a nós!</H4>
                  <Content className="wrapper">
                    <Content className="icon">
                      <Link
                        href="https://www.instagram.com/pixaflowoficial"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaInstagram />
                      </Link>
                    </Content>
                    <Content className="icon">
                      <Link
                        href="https://www.facebook.com/pixaflow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebookF />
                      </Link>
                    </Content>
                    <Content className="icon">
                      <Link
                        href="https://br.linkedin.com/company/pixaflow"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedinIn />
                      </Link>
                    </Content>
                  </Content>
                </Col>
              </Row> */}
            </Container>
          </Footer>
        </Page>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};
