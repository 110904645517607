import { Container, Row, Col } from "reactstrap";
import {
  Page,
  Content,
  Box,
  Background,
  // Card,
  Title,
  H2,
  Break,
  // H3,
  // H4,
  // Text,
  Span,
  Image,
  Link,
  NavbarMain,
  NavbarBrandMain,
  Form,
  Input,
  Button,
  Img,
} from "../../../style";

import Logo from "../../../assets/logo.png";

export const Footer = () => {
  return (
    <div
      className="text-center pb-1 pt-2 mt-4"
      style={{
        backgroundColor: "#1b1b1b",
      }}
    >
      <Img src={Logo} style={{ width: 46 }} />
    </div>
  );
};
