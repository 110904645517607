import React, { useState } from "react";

import axios from "axios";

import { Container, Row, Col } from "reactstrap";
import {
  Page,
  Paper,
  Box,
  Divider,
  Title,
  H2,
  H3,
  Img,
  Text,
  Span,
  Image,
  NavbarMain,
  NavbarBrandMain,
  Form,
  Input,
  Button,
} from "../../style";

import imgLogo from "../../assets/logo.png";

import load from "../../assets/load.gif";

export const ProgramaDeExtensao = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [uf, setUf] = useState("");
  const [course, setCourse] = useState("");
  const [semester, setSemester] = useState("");
  const [about, setAbout] = useState("");
  const [linkedin, setLinkedin] = useState("");

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  function maskPhonePtBr(value) {
    let mask = "";

    mask = value.replace(/\D/g, "");

    if (mask.length !== 0) {
      if (mask.length > 11) {
        mask = mask.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
      } else if (mask.length > 7) {
        mask = mask.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
      } else if (mask.length > 2) {
        mask = mask.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
      } else if (value.trim() !== "") {
        mask = mask.replace(/^(\d*)/, "($1");
      }
    }

    return mask;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    axios
      .post("/form-subscription", {
        form_name: "programa-de-extensao-brasil",
        name: name,
        phone: phone,
        email: email,
        uf: uf,
        course: course,
        semester: semester,
        about: about,
        linkedin: linkedin,
      })
      .then(function (response) {
        console.log(response);

        setLoading(true);
        setSuccess(true);

        setTimeout(() => {
          setLoading(false);
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);

        setLoading(true);
        setSuccess(true);

        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  }

  return (
    <Page>
      <NavbarMain color="light" dark expand="md">
        <Container>
          <NavbarBrandMain href="/">
            <Image src={imgLogo} alt="logo" className="img-fluid" />
            PixaFlow
          </NavbarBrandMain>
        </Container>
      </NavbarMain>

      <Container>
        <Paper className="p-5 mt-5">
          {success ? (
            <>
              {loading ? (
                <Box>
                  <Img src={load} height="30" />
                </Box>
              ) : (
                <>
                  <Box className="py-5">
                    <H2 className="text-primary">Inscrição confirmada!</H2>
                    <H3 className="text-muted mt-4">
                      Agora e só aguardar que em breve entraremos em contato.
                    </H3>
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <Title className="mb-2">Programa de extensão</Title>
              <Text className="text-muted" style={{ fontWeight: 500 }}>
                Inscrições
              </Text>

              <Divider className="mt-1" />

              <Form onSubmit={handleSubmit} className="pt-5" autoComplete="off">
                <Box className="form-group mb-4">
                  <Input
                    type="text"
                    className="form-control form-default"
                    name="name"
                    placeholder="Digite seu nome"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                  />
                </Box>

                <Box className="form-group mb-4">
                  <Input
                    type="email"
                    className="form-control form-default"
                    name="email"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                </Box>

                <Row>
                  <Col className="col-12 col-lg-8">
                    <Box className="form-group mb-4">
                      <Input
                        type="text"
                        className="form-control form-default"
                        name="phone"
                        placeholder="(00) 0000-0000"
                        value={phone}
                        onChange={(event) =>
                          setPhone(maskPhonePtBr(event.target.value))
                        }
                      />
                    </Box>
                  </Col>

                  <Col className="col-12 col-lg-4">
                    <Box className="form-group mb-4">
                      <Input
                        type="text"
                        className="form-control form-default"
                        name="uf"
                        placeholder="UF"
                        value={uf}
                        onChange={(event) => setUf(event.target.value)}
                      />
                    </Box>
                  </Col>
                </Row>

                <Box className="form-group mb-3">
                  <Input
                    className="form-control form-default"
                    type="textarea"
                    name="about"
                    rows="4"
                    placeholder="Conte sobre você"
                    value={about}
                    onChange={(event) => setAbout(event.target.value)}
                  ></Input>
                </Box>

                <Box className="text-start mt-5">
                  <Text className="text-muted" style={{ fontWeight: 500 }}>
                    Escolaridade
                  </Text>
                </Box>

                <Box className="form-group mb-4">
                  <Input
                    type="text"
                    className="form-control form-default"
                    name="course"
                    placeholder="Curso (concluído ou não)"
                    value={course}
                    onChange={(event) => setCourse(event.target.value)}
                  />
                </Box>

                <Box className="form-group mb-4">
                  <Input
                    type="text"
                    className="form-control form-default"
                    name="semester"
                    placeholder="Semestre (se for o caso)"
                    value={semester}
                    onChange={(event) => setSemester(event.target.value)}
                  />
                </Box>

                <Box className="text-start mt-5">
                  <Text className="text-muted" style={{ fontWeight: 500 }}>
                    Social
                  </Text>
                </Box>

                <Box className="form-group mb-4">
                  <Input
                    type="text"
                    className="form-control form-default"
                    name="linkedin"
                    placeholder="Linkedin"
                    value={linkedin}
                    onChange={(event) => setLinkedin(event.target.value)}
                  />
                </Box>

                <Box className="pt-4 text-center">
                  <Button type="submit" className="btn btn-success text-white">
                    <Span className="ml-1">Enviar</Span>
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Paper>
      </Container>
    </Page>
  );
};
