import { Navbar, NavbarBrand } from "reactstrap";
import { Input as BSInput } from "reactstrap";
import { Button as BSButton } from "reactstrap";

import styled from "styled-components";
import bgHome from "../assets/bg-home.png";
import imgCube from "../assets/cube.png";
import imgEducational from "../assets/bg-educational.png";

export const Page = styled.div``;

export const Paper = styled.div`
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 60px;
  letter-spacing: 0.01071em;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
`;

export const Content = styled.div`
  &.bg-page {
    background: #131621;
  }

  &.wrapper {
    display: inline-flex;
  }

  &.wrapper .icon {
    position: relative;
    border: 1px solid #ffffff;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  &.wrapper .icon svg {
    color: #ffffff;
  }

  &.wrapper .icon:hover {
    background-color: #192271;
    border-color: #192271;
    transition: all 0.5s;
    transition-property: all;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
`;

export const Box = styled.div``;

export const NavbarMain = styled(Navbar)`
  padding: 0;
  height: 60px;
  background-color: #171f34 !important;
  box-shadow: 0 5px 2px -5px #000;
`;

export const NavbarBrandMain = styled(NavbarBrand)`
  img {
    height: 45px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 280px;
  background: #161616;

  p {
    font-size: 11pt;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const Background = styled.div`
  &.bg-home {
    min-height: calc(100vh - 60px);
    background-image: url(${bgHome});
    background-size: cover;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-color: #131621;
  }

  &.bg-cube {
    background-image: url(${imgCube});
    background-size: contain;
    background-repeat: no-repeat;
    filter: drop-shadow(2px 4px 2px #0c0c0c);
  }

  &.bg-educational {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-image: url(${imgEducational});
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      background-image: linear-gradient(rgb(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url(${imgEducational}) !important;
      background-size: cover;
      background-position: bottom right;
      background-repeat: no-repeat;
      justify-content: center;
      text-align: center;
      color: #ffffff;
    }
  }
`;

export const Divider = styled.hr``;

export const Title = styled.h1`
  &.text-description {
    color: #207be9;
    font-weight: 400;
    font-size: 58pt;
    font-family: "Jura", Arial, Helvetica, sans-serif !important;

    @media (max-width: 576px) {
      font-size: 26pt;
    }
  }

  &.text-educacional {
    font-size: 2rem !important;

    @media (max-width: 576px) {
      font-size: 16pt !important;
    }
  }

  &.text-home {
    background: -webkit-linear-gradient(#13c7e7, #207be9);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 38pt;
    font-weight: 700 !important;
    line-height: 4rem;

    span {
      background: -webkit-linear-gradient(#13c7e7, #207be9);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      display: block;
    }

    @media (max-width: 576px) {
      font-size: 24pt;
    }
  }
`;

export const H2 = styled.h2`
  &.text-description {
    line-height: 1.5;
    font-family: "Jura", Arial, Helvetica, sans-serif !important;
  }

  &.text-educacional {
    font-size: 4rem !important;

    @media (max-width: 576px) {
      font-size: 24pt !important;
    }
  }
`;

export const Break = styled.br``;

export const H3 = styled.h3``;

export const H4 = styled.h4``;

export const H5 = styled.h5``;

export const Text = styled.p`
  font-size: 14pt;
`;

export const Span = styled.span`
  &.heading-landing__highlight {
    // display: "block";
    // color: #000 !important;
  }
`;

export const Link = styled.a`
  &.btn {
    min-width: 160px;
    border-radius: 30px;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  &.button {
    border: none;
    padding: 20px;
    width: 100%;
    font-size: 24px;
    line-height: 24px;
    border-radius: 0.25rem;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    color: #ffffff;
    transition: all 400ms ease;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    text-decoration: none;
  }

  &.btn-started {
    background: #ff7300;
    transform-style: preserve-3d;
  }

  /* &.btn-started:after {
    top: -100%;
    left: 0px;
    width: 100%;
    padding: 20px;
    position: absolute;
    color: #ffffff;
    background: #25c2b5;
    border-radius: 0.25rem;
    box-sizing: border-box;
    content: "Vamos começar?";
    transform-origin: left bottom;
    transform: rotateX(90deg);
  } */

  &.btn-started:hover {
    background: #25c2b5;
    cursor: "pointer !important";
    transform: scale(1.05);
    /* transform-origin: center bottom; */
    /* transform: rotateX(-90deg) translateY(100%); */
  }

  &.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    padding-top: 5px;
  }
`;

export const Card = styled.div`
  &.card-services:hover {
    cursor: pointer;
  }

  &.card-services:hover > h3 {
    transition: all 1s;
    transition-delay: 0s;
    color: #207be9 !important;
  }

  &.card-services:hover > p {
    transition: all 1s;
    transition-delay: 0s;
    color: #ffffff !important;
  }

  &.card-services:hover > svg.business path {
    animation: animate-business 2s linear forwards;
  }

  &.card-services:hover > svg.product path {
    animation: animate-product 2s linear forwards;
  }

  &.card-services:hover > svg.technology path {
    animation: animate-technology 2s linear forwards;
  }

  @keyframes animate-business {
    0% {
      stroke-dashoffset: 0;
    }
    40% {
      stroke-dashoffset: 1420;
    }
    80% {
      stroke-dashoffset: 2840;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 2840;
      stroke: #ffffff;
    }
  }

  @keyframes animate-product {
    0% {
      stroke-dashoffset: 0;
    }
    40% {
      stroke-dashoffset: 1190;
    }
    80% {
      stroke-dashoffset: 2380;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 2380;
      stroke: #ffffff;
    }
  }

  @keyframes animate-technology {
    0% {
      stroke-dashoffset: 0;
    }
    40% {
      stroke-dashoffset: 1540;
    }
    80% {
      stroke-dashoffset: 3080;
      fill: transparent;
    }
    100% {
      stroke-dashoffset: 3080;
      stroke: #ffffff;
    }
  }
`;

export const Image = styled.img`
  &.animation.up-and-down {
    animation: 2s ease-in-out 0.2s infinite up-and-down;
  }

  @keyframes up-and-down {
    0% {
      transform: translate3d(0, 0, 0);
    }
    50% {
      transform: translate3d(0, 20px, 0);
    }
    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Svg = styled.svg`
  &.icon-services {
    width: 160px;
    fill: transparent;
    stroke-width: 10;
    stroke: #207be9;
  }

  &.icon-services.business path {
    stroke-dasharray: 1420;
  }

  &.icon-services.product path {
    stroke-dasharray: 1190;
  }

  &.icon-services.technology path {
    stroke-dasharray: 1540;
  }
`;

export const Path = styled.path``;

export const Form = styled.form``;

export const Input = styled(BSInput)`
  padding: 0.575rem 0.75rem;
  background-color: #131621;
  border-color: #40496b;
  color: #f9f9f9 !important;

  &:focus {
    background-color: #131621;
    border-color: #40496b;
  }

  &:active {
    background-color: #131621;
    border-color: #40496b;
  }

  ::placeholder {
    color: #6c757d;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #6c757d;
  }

  ::-ms-input-placeholder {
    color: #6c757d;
  }

  &.form-default {
    background-color: inherit;
    border-color: #b9b9b9;
    color: inherit !important;

    &:focus {
      background-color: inherit;
      border-color: #b9b9b9;
    }

    &:active {
      background-color: inherit;
      border-color: #b9b9b9;
    }

    ::placeholder {
      color: #a9a9a9;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: inherit;
    }

    ::-ms-input-placeholder {
      color: inherit;
    }
  }
`;

export const Button = styled(BSButton)`
  min-width: 160px;
  border-radius: 30px;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
`;

export const Img = styled.img``;
