import "./App.css";

/* Routes */
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// pages
import { Home } from "./pages/Home";
import { ProgramaDeExtensao } from "./pages/ProgramaDeExtensao";
import { UnpaidInternship } from "./pages/UnpaidInternship";
import { Educational } from "./pages/Educational";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/programa-de-extensao-brasil"
          element={<ProgramaDeExtensao />}
        />
        <Route path="/unpaid-internship" element={<UnpaidInternship />} />
        <Route path="/educacional" element={<Educational />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
