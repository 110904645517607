import { Container, Row, Col } from "reactstrap";

import { Card } from "../../components/Card/Main";
import { Footer } from "../../components/Footer/Main";

import { FaWhatsapp } from "react-icons/fa";

import {
  Page,
  Content,
  Box,
  Background,
  // Card,
  Title,
  H2,
  Break,
  // H3,
  // H4,
  // Text,
  Span,
  Image,
  Link,
  NavbarMain,
  NavbarBrandMain,
  Form,
  Input,
  Button,
  Img,
} from "../../style";

export const Educational = () => {
  return (
    <Page>
      <Background className="bg-educational">
        <Content className="container">
          <Title className="text-educacional">Pixaflow</Title>
          <H2 className="text-educacional text-primary">Educação</H2>

          <a
            href="#projects"
            className="btn btn-primary py-2 my-3"
            style={{ borderRadius: "30px", minWidth: "200px" }}
          >
            Saiba mais
          </a>
        </Content>
      </Background>

      <Container id="projects" className="py-4">
        <h1 className="text-center mb-5">
          Nossos <span>projetos</span>
        </h1>

        <Card
          title="SD Aulas"
          subtitle="Aprimoramento de desempenho de estudantes"
        />

        <Card
          title="ProfAntenado"
          subtitle="Aprimoramento de desempenho de estudantes"
        />

        <Card
          title="Educa AI"
          subtitle="Análise e predição de evasão e desempenho escolar"
        />

        <Card title="GoEduca" subtitle="Plataforma de jogos educativos" />

        <Card
          title="Mais Alunos"
          subtitle="Captação de alunos para instituições de ensino"
        />

        <Card
          title="Gestão Educacional"
          subtitle="Sistema de gestão de estudantes para IE"
        />

        <Card
          title="DropCiência"
          subtitle="Capacitação em saúde e Tecnologia"
        />
      </Container>

      <Link
        href="https://api.whatsapp.com/send?phone=5561996819313&text=Olá,%20gostaria%20de%20mais%20informações%20sobre%20aulas%20particulares.%20Encontrei%20este%20contato%20no%20site%20pixaflow.com"
        target="_blank"
        className="float"
      >
        <FaWhatsapp className="fa fa-whatsapp my-float" />
      </Link>

      <Footer />
    </Page>
  );
};
