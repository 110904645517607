import React from "react";
import {
  Svg, Path
} from '../../../style';

function Icon() {
  return (
    <Svg
      className="icon-services business"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 -5 512 522"
      xmlSpace="preserve"
    >
      <Path d="M436 0c-24.814 0-45 20.186-45 45 0 10.065 3.439 19.272 9.044 26.776l-73.392 79.636C323.22 150.571 319.688 150 316 150c-9.212 0-17.77 2.802-24.91 7.57l-52.335-39.24c1.328-4.239 2.245-8.659 2.245-13.33 0-24.814-20.186-45-45-45s-45 20.186-45 45c0 4.671.917 9.091 2.245 13.33l-52.335 39.24C93.77 152.802 85.212 150 76 150c-24.814 0-45 20.186-45 45s20.186 45 45 45 45-20.186 45-45c0-4.671-.917-9.091-2.245-13.33l52.335-39.24c7.139 4.768 15.698 7.57 24.91 7.57s17.77-2.802 24.91-7.57l52.335 39.24C271.917 185.909 271 190.329 271 195c0 24.814 20.186 45 45 45s45-20.186 45-45c0-10.065-3.439-19.272-9.044-26.776l73.392-79.636C428.78 89.429 432.312 90 436 90c24.814 0 45-20.186 45-45S460.814 0 436 0zM226 240h-60c-8.291 0-15 6.709-15 15v197h90V255c0-8.291-6.709-15-15-15zM346 300h-60c-8.291 0-15 6.709-15 15v137h90V315c0-8.291-6.709-15-15-15zM466 180h-60c-8.291 0-15 6.709-15 15v257h90V195c0-8.291-6.709-15-15-15zM106 330H46c-8.291 0-15 6.709-15 15v107h90V345c0-8.291-6.709-15-15-15zM497 482H15c-8.291 0-15 6.709-15 15s6.709 15 15 15h482c8.291 0 15-6.709 15-15s-6.709-15-15-15z"></Path>
    </Svg>
  );
}

export default Icon;
